// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import styled, { css } from "styled-components"

import {
  Alert,
  Heading,
  Loading,
  Separator,
  Stack,
  Text,
  TextLink,
  Tile,
} from "@kiwicom/orbit-components"
import { Location, Money } from "@kiwicom/orbit-components/lib/icons"
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import Container from "components/container"
import { EmberCardGroup } from "components/generic/ember-card"
import { EmberTileGroup } from "components/generic/ember-tile-group"
import { MultilineText } from "components/generic/multiline-text"
import Hero from "components/hero"
import Layout from "components/layout"

import { fetchApi } from "utils/fetch-utils"

interface Job {
  id: number
  slug: string
  position: number
  status: string
  optionsPhone: string
  optionsPhoto: string
  optionsCoverLetter: string
  optionsCv: string
  remote: boolean
  countryCode: string
  stateCode: string
  postalCode: string
  minHours: number
  maxHours: number
  title: string
  description: string
  requirements: string
  location: string
  city: string
  country: string
  careersUrl: string
  careersApplyUrl: string
  companyName: string
  department: string
  createdAt: string
  updatedAt: string
  publishedAt: string
  closeAt: string
  employmentTypeCode: string
  categoryCode: string
  experienceCode: string
  educationCode: string
  tags: string[]
  translations: object
  openQuestions: object[]
  salary: Salary
}

interface Salary {
  currency: string
  min: number
  max: number
  period: string
}

interface JobSectionProps {
  jobs: Job[]
}

const IconWrapper = styled.div`
  min-width: 20px;
  text-align: center;
  ${media.tablet(css`
    min-width: 0px;
  `)}
`

const JobsSection = ({ jobs }: JobSectionProps) => {
  const departments = [...new Set(jobs.map((job) => job.department))]

  function salaryText(salary: Salary) {
    let amount: string
    const formatter = Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: salary.currency,
      maximumFractionDigits: 2,
    })
    if (salary.max == null || salary.max == salary.min) {
      // Extra ".replace" means that if a number ends in .00 it's shown without a fraction (e.g. annual salary)
      amount = formatter.format(salary.min).replace(/(\.|,)00$/g, "")
    } else if (salary.min == null) {
      // Extra ".replace" means that if a number ends in .00 it's shown without a fraction (e.g. annual salary)
      amount = formatter.format(salary.max).replace(/(\.|,)00$/g, "")
    } else {
      amount = `${formatter
        .format(salary.min)
        .replace(/(\.|,)00$/g, "")} - ${formatter
        .format(salary.max)
        .replace(/(\.|,)00$/g, "")}`
    }
    return `${amount} per ${salary.period}`
  }

  return (
    <Stack spacing="large">
      {departments.map((department) => {
        return (
          <EmberCardGroup key={department} sectionTitle={department}>
            <EmberTileGroup>
              {jobs
                .filter((job) => job.department == department)
                .sort((a, b) => (a.title < b.title ? -1 : 1))
                .map((job) => {
                  return (
                    <Tile
                      key={job.id}
                      href={job.careersUrl}
                      external
                      description={
                        <Stack spacing="small">
                          <Heading type="title4">{job.title}</Heading>
                          <Stack
                            direction="column"
                            spacing="XSmall"
                            tablet={{ direction: "row", spacing: "medium" }}
                            inline
                          >
                            <Stack
                              direction="row"
                              align="center"
                              spacing="XSmall"
                              inline
                            >
                              <IconWrapper>
                                <Location size="small" />
                              </IconWrapper>
                              <Text>
                                {job.remote == true
                                  ? "Remote"
                                  : `${job.city}, ${job.country}`}
                              </Text>
                            </Stack>
                            <Stack
                              direction="row"
                              align="center"
                              spacing="XSmall"
                              inline
                            >
                              <IconWrapper>
                                <Money />
                              </IconWrapper>
                              <Text>{salaryText(job.salary)}</Text>
                            </Stack>
                          </Stack>
                        </Stack>
                      }
                    />
                  )
                })}
            </EmberTileGroup>
          </EmberCardGroup>
        )
      })}
    </Stack>
  )
}

const page = () => {
  const [loadingJobs, setLoadingJobs] = useState(false)
  const [jobs, setJobs] = useState<Job[]>(null)
  const [jobsError, setJobsError] = useState<string>(null)

  useEffect(() => {
    setLoadingJobs(true)
    fetchApi("https://ember.recruitee.com/api/offers/", "GET").subscribe(
      (response) => {
        if (response && !response.error) {
          setJobs(response?.offers)
        } else {
          setJobsError(JSON.stringify(response.error))
        }
        setLoadingJobs(false)
      }
    )
  }, [])

  return (
    <Layout title="Careers" description="Find out about working at Ember">
      <Hero title="Careers" />
      <Container size="narrow">
        <Stack spacing="XLarge">
          <Stack>
            <Text>
              Ember builds and operates electric bus networks that give people a
              zero-emission, convenient and affordable way to get from
              A&nbsp;to&nbsp;B.
            </Text>
            <Text>
              We're the first company in the world to take a full-stack,
              tech-led approach to bus travel, creating a compelling alternative
              to cars for a much broader customer base. Ember&nbsp;OS, our
              proprietary tech-platform, manages the whole network – everything
              from timetabling and ticketing to charging and maintenance –
              delivering a dramatically better service at lower cost.
            </Text>
            <Heading type="title4">Working at Ember</Heading>
            <Text>
              We're building a team with a huge range of skillsets including
              software engineers, data analysts, drivers, operations and
              everything in between. Regardless of your role, we're looking for
              open-minded people who genuinely believe in building a better
              public transport solution.
            </Text>
            <Text>
              Head Office is in Edinburgh but we also have a hub in Dundee with
              front-line staff. We'll be adding more hubs across Scotland in the
              future.
            </Text>
            <Heading type="title4">Fair Work</Heading>
            <Text>
              We commit to paying the living wage for all roles and support fair
              working practices such as the Scottish Government's Fair Work
              First criteria. Being an equal opportunities employer is important
              to us so please reach out if you have any special requirements or
              concerns. We publish our{" "}
              <TextLink href="/equal-opportunities-policy/">
                Equal Opportunities Policy
              </TextLink>{" "}
              for everyone to review ahead of joining.
            </Text>
          </Stack>
          <Separator />
          <Stack spacing="large">
            <Stack>
              <Heading type="title2">Current Opportunities</Heading>
              <Text>
                If you don't see the perfect role right now, but still think
                you'd be a good fit, email{" "}
                <a href="mailto:talent@ember.to">talent@ember.to</a> to let us
                know what you are interested in – we have new opportunities
                coming up all the time.
              </Text>
            </Stack>
            {loadingJobs ? (
              <Loading loading={loadingJobs} text="Loading Jobs" />
            ) : jobsError ? (
              <Alert type="critical" title="Error Loading Jobs">
                <MultilineText>
                  The full error message is: {jobsError}
                </MultilineText>
              </Alert>
            ) : (
              jobs && <JobsSection jobs={jobs}></JobsSection>
            )}
          </Stack>
        </Stack>
      </Container>
    </Layout>
  )
}

export default page
