import React from "react"

import styled from "styled-components"

import { TileGroup } from "@kiwicom/orbit-components"

type EmberTileGroupProps = React.ComponentProps<typeof TileGroup>

const TileGroupWrapper = styled.div`
  a:hover {
    text-decoration: none;
  }
`

const EmberTileGroup = (props: EmberTileGroupProps) => (
  <TileGroupWrapper>
    <TileGroup {...props} />
  </TileGroupWrapper>
)

export { EmberTileGroup }
